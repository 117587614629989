<template>
  <v-dialog
    v-model="dialog"
    class="dialog"
    max-width="600"
    persistent
  >
    <v-card class="card">
      <v-form ref="form" v-model="isValid">
        <h3 class="text-center font-family-raleway-medium mb-5">

        </h3>
        <v-autocomplete
          v-model="select"
          :items="items"
          item-text="name"
          item-value="criteriaID"
          :search-input.sync="countryValue"
          class="mx-4 font-family-raleway-medium input-border"
          :rules="nameRules"
          label="Select locations to target"
          @input="handleSelect"
          height="60"
          outlined
          flat
        ></v-autocomplete>
        <v-row class="pb-2">
          <v-col class="text-right mt-2" cols="12">
            <v-btn
              class="mr-5"
              style="box-shadow: none"
              @click="cancelUpdateLocation"
              >{{ $t('cancel') }}
            </v-btn>
            <v-btn
              class="button save-btn"
              height="42"
              @click="saveCampaignLocation"
              >{{ $t('save_button') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { getCities, getCoordsOfPlace } from "@/services/autocomplete";

export default {
  name: "ChangeLocation",
  props: {
    dialog: Boolean,
    locationId: Number,
    countryName: String,
  },
  data() {
    return {
      isValid: false,
      select: null,
      items: [],
      coords: {},
      countryValue: "",
      nameRules: [(v) => !!v || "Required"],
    };
  },
  created() {
    this.countryValue = this.countryName;
    this.handleSelect(this.locationId);
    getCities(this.countryName, false)
      .then((res) => {
        this.items = res;
      })
      .catch((err) => console.log(err));
    this.select = this.locationId;
  },
  methods: {
    async handleSelect(val) {
      this.selectedLocationId = val;
      const res = await getCoordsOfPlace(val);
      this.coords.latitude = res?.latitude.toString();
      this.coords.longitude = res?.longitude.toString();
      this.coordsOfCenter = { lat: res.latitude, lng: res.longitude };
      this.coordsOfMarker = { lat: res.latitude, lng: res.longitude };
    },
    saveCampaignLocation() {
      if (this.isValid) {
        const payload = {
          locationId: this.selectedLocationId,
          campaignTargets: {
            country: this.countryValue,
            adLocation: this.coords,
          },
        };
        this.$emit("saveCampaignLocation", payload);
      }
    },
    cancelUpdateLocation() {
      this.$emit("cancelUpdate");
      this.select = this.locationId;
      getCities(this.countryName, false)
        .then((res) => {
          this.items = res;
        })
        .catch((err) => console.log(err));
    },
  },
  watch: {
    countryValue(val) {
      if (val) {
        getCities(val, false)
          .then((res) => {
            this.items = res;
          })
          .catch((err) => console.log(err));
      }
    },
  },
};
</script>

<style scoped></style>
